import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import { transformWorkout } from "@helpers/favouritesHelper"
import CardsContainer from "@global/cards/CardsContainer"
import Seo from "@elements/Seo"
import Cta from "@global/cards/Cta"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Block from "@global/layout/Block"
import Card from "@global/cards/Card"
import WorkoutOfTheDay from "../components/homepage/WorkoutOfTheDay"

const WorkoutsIntro = ({ content }) => {
  return (
    <Fragment>
      <h1>{content.title}</h1>
      <p>{content.caption}</p>
    </Fragment>
  )
}

const NewWorkouts = ({ labels, workouts }) => {
  return (
    <CardsContainer
      heading={labels.newWorkouts}
      orientation="horizontal"
      desktopScroll={true}
    >
      {workouts.map(node => {
        const cardConfig = transformWorkout(node)
        return <Card {...cardConfig} key={`newWorkouts-${node.id}`} />
      })}
    </CardsContainer>
  )
}

const LiveStream = ({ labels, banner }) => {
  return (
    <Block gutters={true} maxWidth="inner">
      <h2>{labels.labels.liveStream}</h2>
      <Cta
        image={banner}
        subHeading={labels.labels.streamHeading}
        link={{
          path: "/live",
          title: "Watch Live"
        }}
      />
    </Block>
  )
}

const WorkoutCategory = ({ category, cards }) => {
  return (
    <CardsContainer
      heading={category.title}
      lead={category.shortDescription}
      buttonPath={category.path}
      buttonText="View all"
      key={category.id}
      orientation="horizontal"
      desktopScroll={true}
    >
      {cards.nodes.map(node => {
        const cardConfig = transformWorkout(node)
        return <Card key={`${category.title}-${node.id}`} {...cardConfig} />
      })}
    </CardsContainer>
  )
}

const Workouts = ({ data, pageContext: { title, parent } }) => {
  const {
    sculptWorkouts,
    sculptCat,
    powerSessions,
    powerCat,
    absWorkouts,
    absCat,
    igniteCat,
    igniteWorkouts,
    speedCat,
    speedSessions,
    bootCampWorkouts,
    bootCampCat,
    resistWorkouts,
    resistCat,
    lowImpactWorkouts,
    lowImpactCat,
    calmeWorkouts,
    calmeCat,
    steadyStateWorkouts,
    steadyStateCat,
    beYogaWorkouts,
    beYogaCat,
    yesYogaWorkouts,
    yesYogaCat,
    pilatesWorkouts,
    pilatesCat,
    introductoryPilatesCat,
    introductoryPilatesWorkouts,
    coreConnectCat,
    coreConnectWorkouts,
    meditationCat,
    meditationSessions,
    pregnancyPowerSessionCat,
    pregnancyPowerSessionWorkouts,
    introductorySculptCat,
    introductorySculptWorkouts,
    BOXhiitWorkouts,
    BOXhiitCat,
    BOXliftWorkouts,
    BOXliftCat,
    pregnancyYogaSessions,
    pregnancyYogaCat,
    dailyWorkouts,
    dailyWorkoutMiscContent,
    postPeri,
    postPeriCat,
    content: { workouts }
  } = data

  return (
    <ProtectedRoute>
      <Layout>
        <Seo title={workouts.meta.title} description={workouts.meta.desc} />
        <Block gutters={true} padding="both" background={"primary-tint"}>
          <WorkoutsIntro content={data.content.workouts.content} />
        </Block>

        <WorkoutOfTheDay
          dailyWorkouts={dailyWorkouts.nodes[0]}
          heading={dailyWorkoutMiscContent.nodes[0].workoutOfTheDayHeading}
          leadText={dailyWorkoutMiscContent.nodes[0].workoutOfTheDayLeadText}
        />

        <Block
          padding="bottom"
          desktopPadding="both-double"
          background="primary-tint"
        >
          <NewWorkouts
            labels={data.content.workouts.labels}
            workouts={data.newWorkouts.nodes}
          />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={sculptCat} cards={sculptWorkouts} />
        </Block>

        <Block padding="both">
          <WorkoutCategory category={postPeriCat} cards={postPeri} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={powerCat} cards={powerSessions} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={absCat} cards={absWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={igniteCat} cards={igniteWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={BOXhiitCat} cards={BOXhiitWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={BOXliftCat} cards={BOXliftWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={speedCat} cards={speedSessions} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={bootCampCat} cards={bootCampWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={resistCat} cards={resistWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={lowImpactCat} cards={lowImpactWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={calmeCat} cards={calmeWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory
            category={steadyStateCat}
            cards={steadyStateWorkouts}
          />
        </Block>

        <Block padding="top">
          <WorkoutCategory category={beYogaCat} cards={beYogaWorkouts} />
        </Block>

        {yesYogaWorkouts.nodes.length > 0 && (
          <Block padding="top">
            <WorkoutCategory category={yesYogaCat} cards={yesYogaWorkouts} />
          </Block>
        )}

        <Block padding="top">
          <WorkoutCategory category={pilatesCat} cards={pilatesWorkouts} />
        </Block>

        <Block padding="top">
          <WorkoutCategory
            category={introductoryPilatesCat}
            cards={introductoryPilatesWorkouts}
          />
        </Block>

        <Block padding="top">
          <WorkoutCategory
            category={coreConnectCat}
            cards={coreConnectWorkouts}
          />
        </Block>

        <Block padding="top">
          <WorkoutCategory
            category={meditationCat}
            cards={meditationSessions}
          />
        </Block>

        <Block padding="top">
          <WorkoutCategory
            category={introductorySculptCat}
            cards={introductorySculptWorkouts}
          />
        </Block>

        <Block padding="both">
          <WorkoutCategory
            category={pregnancyPowerSessionCat}
            cards={pregnancyPowerSessionWorkouts}
          />
        </Block>

        <Block padding="both">
          <WorkoutCategory
            category={pregnancyYogaCat}
            cards={pregnancyYogaSessions}
          />
        </Block>
      </Layout>
    </ProtectedRoute>
  )
}
export const query = graphql`
  query WorkoutsQuery {
    liveStream: datoCmsLiveStreamTime {
      banner {
        gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "460" })
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      workouts {
        meta {
          title
          desc
        }
        content {
          title
          caption
        }
        labels {
          newWorkouts
          liveStream
          streamHeading
        }
      }
    }

    newWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { archived: { ne: true } }
      limit: 12
    ) {
      nodes {
        ...WorkoutCard
      }
    }

    sculptWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 9
      filter: { archived: { ne: true }, path: { regex: "//sculpt/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }

    sculptCat: datoCmsWorkoutCategory(slug: { eq: "sculpt" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    powerSessions: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 12
      filter: { archived: { ne: true }, path: { regex: "//power-session/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }

    powerCat: datoCmsWorkoutCategory(slug: { eq: "power-session" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    absWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/dedikate-minis/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }

    absCat: datoCmsWorkoutCategory(slug: { eq: "dedikate-minis" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    igniteWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/ignite/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }

    igniteCat: datoCmsWorkoutCategory(slug: { eq: "ignite" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    speedSessions: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/speed-session/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }

    speedCat: datoCmsWorkoutCategory(slug: { eq: "speed-session" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    bootCampWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/boot-camp-blast/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    bootCampCat: datoCmsWorkoutCategory(slug: { eq: "boot-camp-blast" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    resistWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/resist-ded/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    resistCat: datoCmsWorkoutCategory(slug: { eq: "resist-ded" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    BOXliftWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/boxlift/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    BOXliftCat: datoCmsWorkoutCategory(slug: { eq: "boxlift" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    BOXhiitWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/boxhiit/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    BOXhiitCat: datoCmsWorkoutCategory(slug: { eq: "boxhiit" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    lowImpactWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/low-impact/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    lowImpactCat: datoCmsWorkoutCategory(slug: { eq: "low-impact" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    calmeWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/calme/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    calmeCat: datoCmsWorkoutCategory(slug: { eq: "calme" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    steadyStateWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: {
        archived: { ne: true }
        path: { regex: "/steady-state-cardio/" }
      }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    steadyStateCat: datoCmsWorkoutCategory(
      slug: { eq: "steady-state-cardio" }
    ) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    beYogaWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/beyoga/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    beYogaCat: datoCmsWorkoutCategory(slug: { eq: "beyoga" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    yesYogaWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/yes-yoga/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    yesYogaCat: datoCmsWorkoutCategory(slug: { eq: "yes-yoga" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    pilatesWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "//pilates/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    pilatesCat: datoCmsWorkoutCategory(slug: { eq: "pilates" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    introductoryPilatesWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: {
        archived: { ne: true }
        path: { regex: "/introductory-pilates/" }
      }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    introductoryPilatesCat: datoCmsWorkoutCategory(
      slug: { eq: "introductory-pilates" }
    ) {
      id
      title
      shortDescription
      path
      summary
      description
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }

    coreConnectWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/core-connect/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    coreConnectCat: datoCmsWorkoutCategory(slug: { eq: "core-connect" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    introductorySculptWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: {
        archived: { ne: true }
        path: { regex: "/introductory-sculpt/" }
      }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    introductorySculptCat: datoCmsWorkoutCategory(
      slug: { eq: "introductory-sculpt" }
    ) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    pregnancyPowerSessionWorkouts: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: {
        archived: { ne: true }
        path: { regex: "/pregnancy-power-session/" }
      }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    pregnancyPowerSessionCat: datoCmsWorkoutCategory(
      slug: { eq: "pregnancy-power-session" }
    ) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    meditationSessions: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/meditation/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    meditationCat: datoCmsWorkoutCategory(slug: { eq: "meditation" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    postPeri: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: {
        archived: { ne: true }
        path: { regex: "/peri-post-menopause-sculpt/" }
      }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    postPeriCat: datoCmsWorkoutCategory(
      slug: { eq: "peri-post-menopause-sculpt" }
    ) {
      id
      title
      shortDescription
      path
      summary
      description
    }

    pregnancyYogaSessions: allDatoCmsWorkout(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
      filter: { archived: { ne: true }, path: { regex: "/pregnancy-yoga/" } }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    pregnancyYogaCat: datoCmsWorkoutCategory(slug: { eq: "pregnancy-yoga" }) {
      id
      title
      shortDescription
      path
      summary
      description
    }
    dailyWorkouts: allDatoCmsWorkoutOfTheDay(
      limit: 1
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        title
        originalId
        weekStart
        monday {
          ...WorkoutCard
        }
        tuesday {
          ...WorkoutCard
        }
        wednesday {
          ...WorkoutCard
        }
        thursday {
          ...WorkoutCard
        }
        friday {
          ...WorkoutCard
        }
        saturday {
          ...WorkoutCard
        }
        sunday {
          ...WorkoutCard
        }
      }
    }
    dailyWorkoutMiscContent: allDatoCmsMiscContent {
      nodes {
        workoutOfTheDayHeading
        workoutOfTheDayLeadText
      }
    }
  }
`

export default Workouts
